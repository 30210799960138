import React, { useEffect, useState } from "react";
import Title from "../components/Title";
import { makeRequest } from "../makeRequest";
import { Button, CircularProgress, TextField } from "@mui/material";
function About() {
  const [mission, setMission] = useState("");
  const [editMission, setEditMission] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);

  async function fetchMission() {
    await makeRequest
      .get("/configs/mission")
      .then((mission) => {
        console.log(mission.data.value);
        setMission(mission.data);
        setEditMission(mission.data.value);
      })
      .catch((error) => {
        console.error("Error fetching mission:", error); // Handle any errors
      });
  }

  useEffect(() => {
    makeRequest
      .get("/users/login", {
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setLoginStatus(response.data.LoggedIn ? true : false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          console.error("Unauthorized: Please log in again.");
          // Optionally redirect the user to a login page or show a message
          // e.g., window.location.href = '/login';
        } else {
          // Handle other errors
          console.error("An error occurred:", error.message);
        }
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await makeRequest.put("/configs/mission", { value: editMission });

      fetchMission();
    } catch (error) {
      console.error("Error saving brand:", error);
    }
  };

  useEffect(() => {
    fetchMission();
  }, []);

  return (
    <div className="w-full ">
      {loginStatus && (
        <div className="w-full h-auto">
          <Title title={"Modifier Mission"}></Title>
          <form
            onSubmit={handleSubmit}
            className="space-y-4 w-full lg:p-20 sm:p-8"
          >
            <TextField
              label="Mission"
              variant="outlined"
              fullWidth
              defaultValue={mission?.value}
              multiline
              rows={6}
              value={editMission}
              onChange={(e) => setEditMission(e.target.value)}
              className="bg-white"
            />
            <div className="flex sm:gap-1 lg:gap-4">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="w-full"
              >
                Mettre a jour
              </Button>
            </div>
          </form>
        </div>
      )}

      <Title title={"Notre Mission"}></Title>

      <div className="w-full h-screen flex justify-center items-start mt-12 p-24 text-3xl text-center">
        {" "}
        {mission.value ? (
          <p className="font fade-up">“{mission?.value}”</p>
        ) : (
          <CircularProgress color="secondary"></CircularProgress>
        )}
      </div>
    </div>
  );
}

export default About;
