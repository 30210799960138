import React, { useEffect } from "react";
import Search from "../components/Search";
import Title from "../components/Title";

function Home() {
  return (
    <div className="w-full h-full block m-0 mt-24 bt-24">
      <div className="w-full h-screen relative  fade-in ">
        <div className="w-screen  absolute border-y-[24px]  overflow-hidden border-black flex h-full bg-gradient-to-r from-gray-800 to-black ">
          <span className="absolute w-screen  h-full bg-gradient-to-r from-purple-900  to-black animate-pulse-slow "></span>
          <span className="absolute w-screen  h-full bg-gradient-to-b  from-black via-transparent to-black z-30"></span>
          <div className="particle"></div>
          <div className="absolute flex h-full w-screen items-center justify-center">
            <img
              src="./assets/silhouette.png"
              className=" absolute animate-pulse-slow w-auto sm-shadow sm:h-1/2 lg:h-3/4"
            ></img>
            <div className="sm:text-7xl relative lg:text-8xl font-extrabold text-white fade-right select-none z-40">
              <h1 className="text-center sm-shadow">No</h1>{" "}
              <h1 className="text-center  sm-shadow">Nego</h1>{" "}
              <h1 className="text-center  sm-shadow">Shoes</h1>
              <div className="absolute w-fit h-fit top-1/2 -translate-y-1/2 left-3/4 translate-x-full">
                <img
                  className="invert brightness-0 h-[10%] fade-right "
                  src="./assets/nonego_logo.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Title title={"Marchandise"}></Title>
      <Search></Search>
    </div>
  );
}

export default Home;
