import React, { useState } from "react";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { makeRequest } from "../makeRequest";

const BrandForm = ({ existingBrand, onSuccess }) => {
  const [name, setName] = useState(existingBrand ? existingBrand.name : "");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { name };

    try {
      if (existingBrand) {
        await makeRequest.put(`/brands/${existingBrand._id}`, payload);
      } else {
        await makeRequest.post("/brands/", payload);
      }
      onSuccess();
    } catch (error) {
      console.error("Error saving brand:", error);
    }
  };

  const deleteBrand = async () => {
    try {
      await makeRequest.delete(`/brands/${existingBrand._id}`);
      onSuccess();
    } catch (error) {
      console.error("Error deleting brand:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 w-full">
      <TextField
        label="Brand Name"
        variant="outlined"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        className="bg-white"
      />
      <div className="flex sm:gap-1 lg:gap-4">
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          className={`${
            !existingBrand ? "w-full" : "w-5/6"
          } transition-all duration-200`}
        >
          {existingBrand ? "Update" : "Create"} Brand
        </Button>
        {existingBrand && (
          <Button
            variant="contained"
            color="error"
            className="w-1/6"
            onClick={deleteBrand}
          >
            Delete
          </Button>
        )}
      </div>
    </form>
  );
};

export default BrandForm;
