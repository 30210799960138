import React from "react";

function Title({ title }) {
  return (
    <div className="h-[100px] relative fade-in w-full mt-32 text-white text-right text-5xl  ">
      <div className="w-screen absolute border-y-[8px] overflow-hidden border-black flex items-center justify-end gap-8 h-full bg-gradient-to-r from-gray-800 to-black px-8">
        <span className="absolute w-screen h-full bg-gradient-to-r from-purple-800  to-black animate-pulse-slow "></span>
        <p className="fade-right sm-shadow">{title}</p>
      </div>
    </div>
  );
}

export default Title;
