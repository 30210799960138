import { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { makeRequest } from "../makeRequest";

function Login() {
  let navigate = useNavigate();

  const [emailReg, setEmailReg] = useState("");
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loginStatus, setLoginStatus] = useState(false);
  const [err, setErr] = useState("");

  // const register = () => {
  //   makeRequest
  //     .post("/users/register", {
  //       email: emailReg,
  //       username: usernameReg,
  //       password: passwordReg,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };

  const login = async (e) => {
    e.preventDefault();
    makeRequest
      .post("/users/login", {
        email: email,
        password: password,
      })
      .then((res) => {
        setErr("");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("refresh", res.data.refreshToken);
        setLoginStatus(true);
        return navigate("/admin");
      })
      .catch((err) => {
        setErr("Err");
      });
  };

  const logout = () => {
    makeRequest
      .post(
        "/users/logout",
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
      })
      .catch(() => {});
    setLoginStatus(false);
  };

  useEffect(() => {
    if (localStorage.refresh) {
      makeRequest
        .get("/users/login", {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((response) => {
          setLoginStatus(response.data.LoggedIn ? true : false);
        })
        .catch(() => {});
    } else {
      setLoginStatus(false);
    }
  }, []);

  return (
    <div className=" w-screen h-screen border-2 flex justify-center items-center">
      <div className=" fade-up justify-start items-center w-fit sm:pl-4 sm:pr-4 lg:pl-10 lg:pr-10 pt-10 pb-10 black-bg  border-black border-8">
        <div>
          {/* {loginStatus && (
            <div className="registration">
              <h1>Registration </h1>
              <label>Email</label>
              <input
                type="email"
                onChange={(e) => setEmailReg(e.target.value)}
              />
              <label>Username</label>
              <input
                type="text"
                onChange={(e) => setUsernameReg(e.target.value)}
              />
              <label>Password</label>
              <input
                type="password"
                onChange={(e) => setPasswordReg(e.target.value)}
              />
              <button onClick={register}>Register</button>
            </div>
          )} */}
          {!loginStatus && (
            <div className="flex flex-col gap-4 max-w-[300px]">
              <h1>Login</h1>
              <form onSubmit={login} className=" flex flex-col gap-2">
                <FormControl>
                  <InputLabel id="email-label">Email</InputLabel>
                  <OutlinedInput
                    label={"Email"}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    required
                    autoComplete="email"
                  ></OutlinedInput>
                </FormControl>

                <FormControl>
                  <InputLabel id="password-label">Password</InputLabel>
                  <OutlinedInput
                    label={"Password"}
                    type="password"
                    name="password"
                    required
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  ></OutlinedInput>
                </FormControl>
                <strong className="text-red-500 text-center">{err}</strong>
                <Button variant={"outlined"} type="submit">
                  Sign In
                </Button>
              </form>
            </div>
          )}
          {loginStatus && (
            <div className="flex justify-center flex-col">
              <div className="p-4 flex justify-center gap-4 text-lg">
                <Link to={"/brandmanager"}> Brands </Link>
                <Link to={"/productform"}> Products </Link>
              </div>

              <Button color="secondary" variant={"outlined"} onClick={logout}>
                Logout
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
