import React, { useEffect, useState } from "react";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { makeRequest } from "../makeRequest";

const ProductItem = ({ product }) => {
  const [images, setImages] = useState({
    img1: null,
    img2: null,
    img3: null,
    img4: null,
    img5: null,
    img6: null,
    img7: null,
    img8: null,
  });

  async function fetchImg(image) {
    const result = await makeRequest.get("/images/" + image);
    return result.data.url;
  }

  async function fetchAndSetImages() {
    try {
      const imageKeys = [
        "img1",
        "img2",
        "img3",
        "img4",
        "img5",
        "img6",
        "img7",
        "img8",
      ];

      const results = await Promise.all(
        imageKeys.map(async (key) => {
          if (product[key]) {
            const url = await fetchImg(product[key]);
            return { key, url };
          }
          return { key, url: null };
        })
      );

      const updatedImages = results.reduce((acc, { key, url }) => {
        acc[key] = url;
        return acc;
      }, {});

      setImages((prevProduct) => ({
        ...prevProduct,
        ...updatedImages,
      }));
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  }

  useEffect(() => {
    fetchAndSetImages();
  }, []);

  useEffect(() => {
    setImages({
      // Reset the images state whenever the product changes
      img1: null,
      img2: null,
      img3: null,
      img4: null,
      img5: null,
      img6: null,
      img7: null,
      img8: null,
    });

    fetchAndSetImages();
  }, [product]);

  return (
    <div className="card fade-up ">
      <div
        className={`face face1 ${
          !product.disponible && "border-red-600 border-4"
        } `}
      >
        <div className="content ">
          {images.img1 ? (
            <img src={images.img1} />
          ) : (
            <div className="w-full h-full text-lg text-white">Pas d'Image</div>
          )}
          <span
            style={{ transition: ".2s !important" }}
            className="bg-black absolute top-0 left-0 p-2 px-4 text-white text-lg"
          >
            {product.size}
          </span>
          <span className="bg-black absolute flex  bottom-0 w-full left-0 p-2 px-4 text-white text-lg">
            {product.name} -{" "}
            <p
              className={` mx-1 ${
                product.category === "Homme"
                  ? "text-cyan-300"
                  : product.category === "Femme"
                  ? "text-pink-300"
                  : "text-amber-300"
              }`}
            >
              {product.category}
            </p>
          </span>
        </div>
      </div>
      <div
        data-content={product.brand}
        className="face face2 brand overflow-hidden border-8 border-black relative"
      >
        <div className="content z-10 w-full flex justify-center items-center ">
          <Link to={`/product/${product._id}`}>
            <Button className="">
              <InfoSharpIcon className="text-white card-button"></InfoSharpIcon>
            </Button>
          </Link>
        </div>
        <div
          className={`absolute flex justify-center items-center text-center gap-4 text-white bottom-0 w-full h-[80px] text-4xl z-10 `}
        >
          <p className="">{product.price}$</p>
          {product.oldPrice && (
            <p
              className={` ${product.oldPrice && "text-red-500 line-through"}`}
            >
              {product.oldPrice}$
            </p>
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
