import React from "react";
import Title from "../components/Title";

function Guide() {
  return (
    <div>
      <Title title={"Guide"}></Title>
      <div className="w-full min-h-screen h-fit flex flex-col mb-36  items-center">
        <div className="sm:w-full lg:w-3/4 mt-8 ">
          <p className="text-lg flex px-2">
            1. Pour se procurer une paire de souliers, il faut tout d'abord
            choisir une paire et cliquer sur l'icon
            <p className="font-sans clear-both">« i ».</p>
          </p>
          <img
            className="border-4 border-black"
            src={`${process.env.PUBLIC_URL}/assets/Guide_1.jpg`}
          ></img>
        </div>
        <div className="sm:w-full lg:w-3/4 mt-8 ">
          <p className="text-lg flex px-2">
            2. Sur la page de la paire de souliers, il faut cliquer sur le
            bouton « Faire une demande ».
          </p>
          <img
            className="border-4 border-black"
            src={`${process.env.PUBLIC_URL}/assets/Guide_2.jpg`}
          ></img>
        </div>
        <div className="sm:w-full lg:w-3/4 mt-8  ">
          <p className="text-lg flex px-2">
            3. Remplir les informations indiqués et soumettre une demande. Par
            la suite, il faudra simplement patienté le retour du vendeur qui
            contactera via la méthode de contact donnée.
          </p>
          <img
            className="border-4 border-black"
            src={`${process.env.PUBLIC_URL}/assets/Guide_3.jpg`}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Guide;
