import React from "react";
import Title from "../components/Title";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";

function Contact() {
  return (
    <div>
      <Title title={"Contact"}></Title>
      <div className="w-screen h-screen flex justify-center items-start mt-20 lg:p-24">
        {" "}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "18px" }}>Nom</TableCell>
                <TableCell sx={{ fontSize: "18px" }}>Courriel</TableCell>
                <TableCell sx={{ fontSize: "18px" }}>Phone</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontSize: "18px" }}>
                  Giullio Mazzella
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>
                  nnshoesmtl@gmail.com
                </TableCell>
                <TableCell sx={{ fontSize: "18px" }}>(438) 878-7789</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Contact;
