import React, { useState } from "react";
import {
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  FormLabel,
  Box,
} from "@mui/material";
import { render } from "@react-email/render";
import Email from "./emails/email";
import { makeRequest } from "../makeRequest";

function RequestForm({ product }) {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(null);
  const [contactMethod, setContactMethod] = useState("");
  const [fullName, setFullName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [completed, setCompleted] = useState(false);

  const emailHtml = render(<Email registration={formData} />);

  const sendRequest = async (data) => {
    const mailOptions = {
      from: "nnshoesmtl@gmail.com",
      to: "nnshoesmtl@gmail.com",
      subject: "Demande d'achat ",
      html: `<div><p>Nom Complet : ${fullName} </p></br><p>Contact Method : ${contactMethod} </p></br><p>Contact Method : ${contactInfo} </p></br><p>Item: <a href="https://nonegoshoes.com/product/${product._id}">${product.name}</a></p></div>`,
    };
    await makeRequest
      .post("/products/email", { mailOptions })
      .then(async (response) => {
        setCompleted(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendRequest();
  };

  const handleRadioChange = (event) => {
    setContactMethod(event.target.value);
    setContactInfo(""); // Reset contact info when method changes
  };

  const handleInputChange = (event) => {
    setContactInfo(event.target.value);
  };

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  return (
    <Box sx={{ mt: 3 }}>
      {!showForm ? (
        <div>
          {product.disponible ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleButtonClick}
            >
              Faire une demande
            </Button>
          ) : (
            <Button variant="contained" disabled color="error">
              Non dispo
            </Button>
          )}
        </div>
      ) : (
        <Box>
          <form onSubmit={handleSubmit}>
            <FormControl className="flex items-start" component="fieldset">
              <TextField
                label="Nom Complet"
                value={fullName}
                onChange={handleNameChange}
                fullWidth
                margin="normal"
              />
              <FormLabel component="legend" color="secondary">
                Comment souhaitez-vous être contacté ?
              </FormLabel>
              <RadioGroup
                name="contactMethod"
                value={contactMethod}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="phone"
                  control={<Radio color="secondary" />}
                  label="Téléphone"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio color="secondary" />}
                  label="Email"
                />
                <FormControlLabel
                  value="txt"
                  control={<Radio color="secondary" />}
                  label="Texte (SMS)"
                />
              </RadioGroup>

              {contactMethod && (
                <TextField
                  label={
                    contactMethod === "phone"
                      ? "Numéro de téléphone"
                      : contactMethod === "email"
                      ? "Adresse email"
                      : "Numéro pour les textos"
                  }
                  value={contactInfo}
                  onChange={handleInputChange}
                  fullWidth
                  margin="normal"
                />
              )}
              <Button
                disabled={contactInfo === "" || fullName === ""}
                variant="contained"
                color="secondary"
                type="submit"
              >
                Faire Ma Demande!
              </Button>
            </FormControl>
          </form>
        </Box>
      )}
    </Box>
  );
}

export default RequestForm;
