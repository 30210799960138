import React from "react";
import LoginIcon from "@mui/icons-material/Login";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-zinc-900 absolute bottom-0 w-full flex justify-center">
      <div className="sm:w-full lg:w-3/4 flex justify-between items-center">
        <p className="p-8 text-white">
          &copy; 2024 No Nego Shoes. All rights reserved.
        </p>
        <Link to={"/login"} className="lg:mr-24 sm:mr-4">
          {" "}
          <LoginIcon className="text-white"></LoginIcon>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
