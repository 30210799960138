import React, { useState, useEffect, useRef } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";

const Nav = () => {
  let [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("/Home");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, page) => {
    setValue(page);
    navigate(page);
  };

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <div className=" w-full fixed top-0 min-h-24 left-0 z-20 fade-in font-Bebas">
      <div className=" absolute left-4 top-8 w-12 h-12 bg-white rounded-full cursor-pointer sm:block md:hidden">
        <button
          className={`navbar-toggle z-20  border-2 border-black ${
            open ? "open" : ""
          } absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]`}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span className="toggle-icon rounded-full"></span>
          <span className="toggle-icon rounded-full"></span>
          <span className="toggle-icon rounded-full"></span>
        </button>
      </div>
      <div className="md:flex shadow-md sm:hidden items-center justify-between relative bg-white py-2 md:px-10 px-7">
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs "
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab
              value="/Guide"
              label={<p className=" text-lg">Comment Acheter?</p>}
            />
            <Tab
              value="/Home"
              className="h-fit "
              icon={
                <span className="w-fit h-full flex  justify-center items-center">
                  <img
                    className="w-16"
                    src={`${process.env.PUBLIC_URL}/assets/nonego_logo.png`}
                  ></img>
                </span>
              }
            ></Tab>

            <Tab value="/About" label={<p className=" text-lg">À propos</p>} />
            <Tab value="/Contact" label={<p className=" text-lg">Contact</p>} />
          </Tabs>
        </Box>
      </div>
      <div className="absolute md:hidden top-1/2 left-1/2 translate-x-[-50%] translate-y-[-30%]">
        <img
          className="w-12"
          src={`${process.env.PUBLIC_URL}/assets/nonego_logo.png`}
        ></img>
      </div>

      <div
        className={`sm:flex md:hidden ${
          !open ? "translate-x-[-100%]" : "translate-x-0"
        }  duration-300 items-start absolute h-screen top-1/2  translate-y-16 border-4 border-black  z-30 justify-between bg-white py-2 md:px-10 px-7`}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          orientation="vertical"
          aria-label="wrapped label tabs "
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            value="/Home"
            label={<p className="">Home</p>}
            className=" font-Bebas"
            onClick={() => {
              setOpen(false);
            }}
          ></Tab>

          <Tab
            value="/Guide"
            label={
              <p
                className=""
                onClick={() => {
                  setOpen(false);
                }}
              >
                Comment Acheter?
              </p>
            }
          />

          <Tab
            value="/About"
            label={
              <p
                className=""
                onClick={() => {
                  setOpen(false);
                }}
              >
                À propos
              </p>
            }
          />
          <Tab
            value="/Contact"
            label={
              <p
                className=" font-Bebas"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Contact
              </p>
            }
          />
        </Tabs>
      </div>
    </div>
  );
};

export default Nav;
