import React from "react";
import ProductForm from "../components/ProductForm";
import BrandManager from "../components/BrandManager";
import { Link } from "react-router-dom";
import Title from "../components/Title";

function Admin() {
  return (
    <div className="w-screen h-screen">
      {" "}
      <Title title={"Administration"}></Title>{" "}
      <div className="fade-up flex justify-center mt-32 items-center gap-4 text-lg">
        <Link to={"/brandmanager"}> Brands </Link>
        <Link to={"/productform"}> Products </Link>
        {/* <BrandManager></BrandManager>
    <ProductForm></ProductForm> */}
      </div>
    </div>
  );
}

export default Admin;
