import {
  Body,
  Link,
  Container,
  Column,
  Head,
  Heading,
  Html,
  Img,
  Row,
  Section,
  Text,
  Font,
  Preview,
} from "@react-email/components";
import * as React from "react";

export const Email = ({ registration }) => {
  const formattedDate = new Intl.DateTimeFormat("en", {
    dateStyle: "long",
    timeStyle: "short",
  }).format(Date.now());

  return (
    <Html style={{ background: "#232323", width: "100%" }}>
      <Head>
        <Font
          fontFamily="Poppins"
          fallbackFontFamily="Arial"
          webFont={{
            url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
            format: "woff2",
          }}
          fontWeight={400}
          fontStyle="normal"
        />
      </Head>
      <Body
        style={{ background: "#232323", width: "100%", fontFamily: "Arial" }}
      >
        <Container style={{ background: "#232323" }}>
          <Section
            style={{ background: "#232323", paddingBottom: "20px" }}
          ></Section>
          <Section
            style={{
              border: "solid 4px #ab0000",
              background: "black",
              paddingBottom: "20px",
            }}
          >
            <Preview>Afterwork</Preview>
            <Section style={logo}>
              <Img
                src={`https://afterwork-general.s3.amazonaws.com/email-pic.jpg`}
              />
            </Section>
            <Section style={content}>
              <Text style={{ textAlign: "end", padding: "0px 8px" }}>
                <b>Date: </b>
                {formattedDate}
              </Text>
              <Row style={{ ...boxInfos, paddingBottom: "0" }}>
                <Column>
                  <Heading
                    style={{
                      fontSize: 32,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Hello {registration?.firstname} {registration?.lastname},
                  </Heading>
                  <Heading
                    as="h2"
                    style={{
                      fontSize: 26,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    We have received your registration request.
                  </Heading>

                  <Text style={{ ...paragraph, marginTop: -5 }}>
                    <b>
                      You can make a transfer to the following address:
                      info@afterworkleagues.com.{" "}
                    </b>
                  </Text>
                  <Text style={{ ...paragraph, marginTop: -5 }}>
                    <b>Q: League. </b>
                  </Text>
                  <Text style={{ ...paragraph, marginTop: -5 }}>
                    <b>A: Afterwork. </b>
                  </Text>
                </Column>

                <Section style={{ padding: "0px 40px" }}>
                  <Text style={{ ...paragraph }}>
                    <b>Thank you for trusting AW Leagues inc.</b>
                  </Text>
                </Section>
                <Section style={{ padding: "0px 40px" }}>
                  <Img src="https://afterwork-general.s3.amazonaws.com/image.png"></Img>
                  <Text>
                    Donald Maisonneuve<br></br>
                    Les ligues AW inc.
                  </Text>
                </Section>
              </Row>
            </Section>
            <Section>
              <Text
                style={{
                  padding: "30px",
                  textAlign: "center",
                  fontSize: 12,
                  color: "rgb(255,255,255, 0.7)",
                }}
              >
                More than a league.
              </Text>

              <Text
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  color: "rgb(255,255,255, 0.7)",
                }}
              >
                © 2024 | AW Leagues Inc. Montreal, CA |{" "}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href="www.afterworkleagues.com"
                  style={{
                    fontSize: 12,
                    color: "rgb(255,255,255, 0.7)",
                  }}
                >
                  www.afterworkleagues.com
                </Link>
              </Text>
            </Section>
          </Section>
        </Container>
      </Body>
    </Html>
  );
};

export default Email;

const paragraph = {
  fontSize: 16,
};

const logo = {
  borderBottom: "solid 2px #ab0000",
  background: "#232323",
  display: "flex",
  justifyContent: "center",
};

const content = {
  border: "1px solid rgb(0,0,0, 0.1)",
  borderRadius: "3px",
  background: "black",
  color: "white",
  overflow: "hidden",
};

const boxInfos = {
  padding: "20px 40px",
};
